import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { LoadingController, ModalController, PopoverController, ToastController, AlertController, Platform } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class ComponentsService {

	private loading;
	isToastPresent = false;
	isModalPresent: any = false;
	visible_guide: any = true;

	constructor(
		@Inject(LOCALE_ID) private locale: string,
		public modalController: ModalController,
		private loadingCtrl: LoadingController,
		private toastCtrl: ToastController,
		public popoverController: PopoverController,
		public alertController: AlertController,
		public platform: Platform
	) {

	}

	isApp() {
		if (this.platform.width() > 600) {
			return false;
		} else {
			return true;
		}
	}

	showLoader(message = 'Cargando...') {
		return new Promise((resolve, reject) => {
			if (!this.loading) {
				this.loadingCtrl.create({
					message: message + '<p><br>No cierres esta pagina.</p>',
					cssClass: 'quanto-loader',
					spinner: 'circular'
				}).then(res => {
					this.loading = res;
					this.loading.present();
					resolve(true);
				})
			}
		})
	}

	dismissLoader() {
		if (this.loading) {
			this.loading.dismiss();
			this.loading = null;
		}
	}

	async showToast(message, type: any = 'success') {
		if (!this.isToastPresent) {
			let icon = '';
			if (type == 'success') {
				icon = 'checkmark-circle';
			} else {
				icon = 'close-circle';
			}
			this.isToastPresent = true;
			const toast = await this.toastCtrl.create({
				message: message,
				cssClass: type,
				icon: icon,
				position: 'bottom',
				duration: 2000,
				htmlAttributes: { tabindex: undefined },
				buttons: [
					{
						side: 'end',
						role: '',
						icon: 'close',
						handler: () => { },
					}
				],
			});
			await toast.present();
			await toast.onDidDismiss().then(() => (this.isToastPresent = false));
		}
	}

	showAlert(title, message, buttons) {
		this.alertController.create({
			header: title,
			message: message,
			buttons: buttons
		}).then(alert => alert.present());
	}


	preventTouch(ev: any) {
		ev.preventDefault();
		ev.stopPropagation();
		ev.stopImmediatePropagation();
	}

	decimalOnly(event): boolean {
		let pattern = /^[0-9]*\.?[0-9]*$/;
		let result = pattern.test(event.key);
		return result;
	}

	numericOnly(event): boolean {
		let pattern = /^[0-9]*$/;
		let result = pattern.test(event.key);
		return result;
	}

	scrollTo(id, behavior: any = 'smooth') {
		setTimeout(() => {
			document.getElementById(id).scrollIntoView({
				behavior,
				block: 'center',
				inline: 'nearest'
			});
		}, 200);
	}

	fixed(numberToConvert) {
		return parseFloat(numberToConvert.toFixed(2));
	}

	formatToCurrency(amount) {
		return (Number(amount)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
	}

	formatCurrency(valor: number): string {
		console.log(this.locale);

		const valorFormateado = valor.toLocaleString(this.locale, {
			style: 'currency',
			currency: 'USD',
		});
		return valorFormateado;
	}
}
