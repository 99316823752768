import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase/app';
import { ComponentsService } from '../components/components.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { BehaviorSubject } from 'rxjs';
import { NavigationExtras, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { from } from 'rxjs';
import CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    authState = new BehaviorSubject(false);
    token: any;
    account: any;
    quanto_dte: any;
    account_data: any;

    constructor(
        public storage: Storage,
        public database: AngularFireDatabase,
        private angularAuth: AngularFireAuth,
        private router: Router,
        public db: AngularFirestore,
        public components: ComponentsService,
        public http: HttpClient
    ) {
        this.storage.create();
        this.angularAuth.authState.subscribe((firebaseUser) => {
            this.token = firebaseUser.uid;
            this.ifLoggedIn();
        }, err => {
            console.log(err);
            this.logOut();
        })
    }

    isAuthenticated() {
        return this.authState.value;
    }

    ifLoggedIn() {
        this.storage.get('token').then((response) => {
            if (response) {
                this.token = response;
            }

        });

        this.storage.get('account').then((response) => {
            if (response) {
                this.account = response;
                this.quantoSettings();
                this.checkAccount();
            }
        });
    }

    checkAccount() {
        this.db.collection(`quanto/general/admins`).ref
            .doc(this.account)
            .onSnapshot(snapshots => {
                this.account_data = snapshots.data();
                console.log(this.account_data);

            })
    }

    quantoSettings() {
        this.db.collection(`quanto`).ref
            .doc(`dte`)
            .onSnapshot(snapshot => {
                this.quanto_dte = snapshot.data();
            })
    }

    getAuthState() {
        return from(this.angularAuth.authState);
    }

    logIn(email, password) {
        return new Promise((resolve, reject) => {

            this.db.collection(`quanto/general/admins`).ref
                .where('email', '==', email)
                .get()
                .then(snapshots => {
                    if (snapshots.empty) {
                        this.components.dismissLoader();
                        this.components.showAlert('error', 'No tienes acceso a esta area', ['Aceptar']);
                        reject(false);
                    } else {
                        this.angularAuth.auth.signInWithEmailAndPassword(email, password).then((response) => {
                            this.token = response.user.uid;
                            snapshots.forEach(element => {
                                this.account = element.id;
                                this.storage.set('account', this.account).then(response => {
                                    resolve(true);
                                });
                            });

                        }, (err) => {
                            reject(err);
                        });
                    }
                })
        });
    }

    cancelAccount(account_key) {
        return new Promise((resolve, reject) => {
            let batch = this.db.firestore.batch();

            batch.delete(this.db.firestore.collection(`accounts`).doc(account_key));


            this.db.collection('devices').ref
                .where('account_key', '==', account_key)
                .get()
                .then(snapshots => {
                    snapshots.forEach(element => {
                        batch.delete(this.db.firestore.collection(`devices`).doc(element.id));
                    });

                    this.db.collection('extras').ref
                        .where('account_key', '==', account_key)
                        .get()
                        .then(snapshots => {
                            snapshots.forEach(element => {
                                batch.delete(this.db.firestore.collection(`extras`).doc(element.id));
                            });

                            this.db.collection('licenses').ref
                                .where('account_key', '==', account_key)
                                .get()
                                .then(snapshots => {
                                    snapshots.forEach(element => {
                                        batch.delete(this.db.firestore.collection(`licenses`).doc(element.id));
                                    });

                                    batch.commit().then(data => {
                                        resolve(true);
                                    }, err => {
                                        reject();
                                    });

                                })

                        })
                })

        })
    }

    logOut() {
        return this.angularAuth.auth.signOut().then(() => {
            this.storage.remove('token').then(() => {
                this.router.navigate(['login'], { replaceUrl: true, clearHistory: true } as NavigationExtras);
                this.authState.next(false);
            });
        });
    }

    registerUser(data) {
        return new Promise((resolve, reject) => {
            console.log(String(CryptoJS.SHA1(data.email)));
            data.password = String(CryptoJS.SHA1(data.email));

            this.angularAuth.auth.createUserWithEmailAndPassword(data.email, String(CryptoJS.SHA1(data.email))).then(user_db => {
                let batch = this.db.firestore.batch();

                let account = {
                    creation_date: firebase.firestore.FieldValue.serverTimestamp(),
                    creation_status: 'sales',
                    owner: user_db.user.uid,
                    admins: [
                        user_db.user.uid
                    ],
                    email: data.email,
                    name: data.name,
                    phone: data.phone,
                    country: data.country,
                    logo: false,
                    type: data.business_type,
                    timer: 60,
                    products_order: {
                        sort_by: 'creation_date',
                        order: 'desc'
                    },
                    first_payment: false,
                    currency: data.currency,
                }

                let id_account = this.db.createId();

                batch.set(this.db.firestore.collection(`accounts`).doc(id_account), account);

                batch.set(this.db.firestore.collection(`users`).doc(user_db.user.uid), {
                    email: data.email,
                    creation_date: firebase.firestore.FieldValue.serverTimestamp(),
                    name: data.user_name,
                    lastname: data.user_lastname,
                    occupied: true,
                    extra: false,
                    phone: data.phone
                });

                // CREAR LICENCIA DE LA CUENTA

                let membership_key = this.db.createId();

                let license = {
                    membership: data.membership,
                    active: true,
                    renewed: true,
                    free_trial: true,
                    creation_date: firebase.firestore.FieldValue.serverTimestamp(),
                    account_key: id_account,
                }

                batch.set(this.db.firestore.collection(`licenses`).doc(membership_key), license);

                let branch_key = this.db.createId();

                batch.set(this.db.firestore.collection(`accounts/${id_account}/branches`).doc(branch_key), {
                    address: data.address,
                    phone: data.phone,
                    account_key: id_account,
                    name: data.name,
                    creation_date: firebase.firestore.FieldValue.serverTimestamp(),
                    tables: false,
                    tip: 0,
                    active_tip: false,
                    country: data.country,
                    type: 'branch'
                });

                batch.set(this.db.firestore.collection(`accounts/${id_account}/users`).doc(user_db.user.uid), {
                    user_key: user_db.user.uid,
                    email: data.email,
                    name: data.user_name,
                    lastname: data.user_lastname,
                    creation_date: firebase.firestore.FieldValue.serverTimestamp(),
                    admin_roles: ['all'],
                    pos_roles: ['all'],
                    owner: true,
                    kds: true,
                    admin: true,
                    pos: true,
                    occupied: true,
                    extra: false,
                    branches: [branch_key],
                    code: '0000',
                });

                console.log(data);

                this.http.post(`${environment.api}/send_sales_client_email`, data).subscribe(() => { })

                batch.commit().then(() => {
                    this.components.dismissLoader();
                    resolve(true);
                }, err => {
                    console.log(err);

                    reject(err);
                })
            })
        })
    }
}
