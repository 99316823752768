import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import 'firebase/storage'
import { Storage } from '@ionic/storage';
import { AuthService } from '../auth/auth.service';
import { ComponentsService } from '../components/components.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { DteService } from '../dte/dte.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	constructor(
		public firestore: AngularFirestore,
		public db: AngularFirestore,
		public storage: Storage,
		public auth: AuthService,
		public components: ComponentsService,
		public router: Router,
		public dte: DteService,
		private http: HttpClient
	) {

	}

	/**
	 * It returns a promise that resolves to the value of the key passed in
	 * @param key - The key to store the data under.
	 * @returns A promise that resolves to a string.
	 */
	getStorage(key) {
		return new Promise((resolve, reject) => {
			this.storage.get(key).then((response) => {
				resolve(String(response));
			});
		})
	}

	///////////////////////////////
	// GENERIC CRUD API REQUESTS
	///////////////////////////////

	/**
	 * It returns a promise that resolves to an array of all documents in a collection
	 * @param {string} collection - The name of the collection you want to get the documents from.
	 * @returns An array of objects.
	 */
	getAllDocuments(collection: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.db.collection(collection)
				.get()
				.toPromise()
				.then((querySnapshot) => {
					let arr = [];
					querySnapshot.forEach(function (doc) {
						var obj = JSON.parse(JSON.stringify(doc.data()));
						obj.$key = doc.id
						arr.push(obj);
					});

					if (arr.length > 0) {
						resolve(arr);
					} else {
						resolve(null);
					}


				})
				.catch((error: any) => {
					reject(error);
				});
		});
	}

	/**
	 * It returns a promise that resolves to a document from a collection with a given documentId
	 * @param collection - The name of the collection you want to get the document from.
	 * @param documentId - The id of the document you want to get.
	 * @returns A promise that resolves to a document object.
	 */
	getDocument(collection, documentId) {
		return new Promise((resolve, reject) => {
			this.db.collection(collection).doc(documentId)
				.get()
				.toPromise()
				.then((snapshot) => {
					let doc = snapshot.data();
					doc.$key = snapshot.id;
					resolve(doc);
				}).catch((error: any) => {
					reject(error);
				});
		})
	}

	/**
	 * It deletes a document from a collection
	 * @param {string} collectionName - The name of the collection you want to delete the document from.
	 * @param {string} docID - The ID of the document you want to delete.
	 * @returns A promise that resolves to the object that was deleted.
	 */
	deleteDocument(collectionName: string, docID: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.db
				.collection(collectionName)
				.doc(docID)
				.delete()
				.then((obj: any) => {
					resolve(obj);
				})
				.catch((error: any) => {
					reject(error);
				});
		});
	}

	/**
	 * This function takes a collection name and a data object as parameters, and returns a promise that
	 * resolves with the newly created document's id
	 * @param {string} collectionName - The name of the collection you want to add the document to.
	 * @param {any} dataObj - This is the object that you want to add to the collection.
	 * @returns A promise that resolves to the object that was added to the database.
	 */
	addDocument(collectionName: string, dataObj: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.db.collection(collectionName).add(dataObj)
				.then((obj: any) => {
					resolve(obj);
				})
				.catch((error: any) => {
					reject(error);
				});
		});
	}

	/**
	 * This function updates a document in a collection
	 * @param {string} collectionName - The name of the collection you want to update.
	 * @param {string} docID - The ID of the document you want to update.
	 * @param {any} dataObj - This is the object that you want to update.
	 * @returns A promise that resolves to the updated document.
	 */
	updateDocument(collectionName: string, docID: string, dataObj: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.db
				.collection(collectionName)
				.doc(docID)
				.update(dataObj)
				.then((obj: any) => {
					resolve(obj);
				})
				.catch((error: any) => {
					reject(error);
				});
		});
	}

	/**
	 * This function takes in a collection name, a document ID, and a data object, and then sets the
	 * document with the given ID in the given collection to the given data object
	 * @param {string} collectionName - The name of the collection you want to add the document to.
	 * @param {string} docID - The document ID.
	 * @param {any} dataObj - This is the object that you want to add to the document.
	 * @returns A promise that resolves to the object that was set.
	 */
	setDocument(collectionName: string, docID: string, dataObj: any) {
		return new Promise((resolve, reject) => {
			this.db
				.collection(collectionName)
				.doc(docID)
				.set(dataObj)
				.then((obj: any) => {
					resolve(obj)
				})
				.catch((error: any) => {
					reject(error);
				})
		})
	}

	/**
	 * It returns a reference to a collection in the database
	 * @param collection - The name of the collection you want to get a reference to.
	 * @returns A reference to the collection.
	 */
	getRef(collection) {
		return this.db.collection(collection);
	}

	/**
	 * It generates a random string of a given length
	 * @param length - The length of the random string.
	 * @returns A random string of characters.
	 */
	makeid(length) {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() *
				charactersLength));
		}
		return result;
	}

	removeAccount(account) {
		return new Promise((resolve, reject) => {
			let batch = this.db.firestore.batch();

			let backup_account = account;
			backup_account.account_key = account.$key;

			batch.delete(this.db.firestore.collection(`accounts`).doc(account.$key));

			let collections = ['categories',
				'clients',
				'closings',
				'deliveries',
				'details',
				'devices',
				'discounts',
				'favorites',
				'floorplans',
				'giftcard_payments',
				'giftcards',
				'inventory_record',
				'items',
				'items_branches',
				'items_returns',
				'kds_items',
				'modifiers',
				'order_returns',
				'payment_gateways',
				'printers',
				'promotions',
				'suppliers',
				'taxes',
				'ticket_discounts',
				'ticket_items',
				'ticket_payments',
				'ticket_refunds',
				'tickets',
				'users']

			collections.forEach(collection => {
				this.db.collection(`accounts/${account.$key}/${collection}`).ref
					.get()
					.then(snapshots => {
						if (!snapshots.empty) {
							snapshots.forEach(element => {
								batch.delete(this.db.firestore.collection(`accounts/${account.$key}/${collection}`).doc(element.id));
							})
						}
					})
			})

			this.db.collection('devices').ref
				.where('account_key', '==', account.$key)
				.get()
				.then(snapshots => {

					backup_account['devices'] = [];

					snapshots.forEach(element => {
						let device = element.data();
						device.$key = element.id;
						backup_account['devices'].push(device)
						batch.delete(this.db.firestore.collection(`devices`).doc(element.id));

						if (device.activeUID) {
							let postData = {
								device: device.$key,
								account: account.$key,
								admin: account.owner,
								uid: device.activeUID
							}

							this.http.post(`${environment.api}/remove_anonymous`, postData).subscribe(response => {
								console.log('se logro');
							})
						}
					});

					this.db.collection('extras').ref
						.where('account_key', '==', account.$key)
						.get()
						.then(snapshots => {
							backup_account['extras'] = [];

							snapshots.forEach(element => {
								let extra = element.data();
								extra.$key = element.id;
								backup_account['extras'].push(extra)
								batch.delete(this.db.firestore.collection(`extras`).doc(element.id));
							});

							this.db.collection('licenses').ref
								.where('account_key', '==', account.$key)
								.get()
								.then(snapshots => {

									snapshots.forEach(element => {
										let license = element.data();
										license.$key = element.id;
										backup_account['license'] = license;
										batch.delete(this.db.firestore.collection(`licenses`).doc(element.id));
									});

									backup_account.cancellation_date = new Date();

									batch.set(this.db.firestore.collection(`quanto/accounts/cancellations`).doc(account.$key), backup_account);

									batch.commit().then(data => {
										resolve(true);
									}, err => {
										console.log(err);

										reject();
									});

								})

						})
				})

		})
	}

	sendChat(chatbox_key, data_message, message) {
		return new Promise((resolve, reject) => {
			let batch = this.db.firestore.batch();

			batch.update(this.db.firestore.collection(`quanto/support/chats`).doc(chatbox_key), data_message);

			let id_message = this.db.createId();

			batch.set(this.db.firestore.collection(`quanto/support/chats/${chatbox_key}/messages/`).doc(id_message), message)

			batch.commit().then(data => {
				resolve(true);
			}, err => {
				reject(err);
			})

		})
	}

	sendHardwareInvoice(billing_info, payment, items) {
		return new Promise((resolve, reject) => {
			let batch = this.db.firestore.batch();


			let dte_number;
			if (billing_info.export_required) {
				dte_number = this.auth.quanto_dte.current_fexe_number + 1;
			} else {
				if (billing_info.ccf_required) {
					dte_number = this.auth.quanto_dte.current_ccfe_number + 1;
				} else {
					dte_number = this.auth.quanto_dte.current_fe_number + 1;
				}
			}


			this.dte.getHardwareJson(billing_info, payment, items, dte_number).then(json => {
				this.dte.signDte(json).then(dteResponse => {

					let id_payment = this.db.createId();

					batch.set(this.db.firestore.collection(`hardware_payments`).doc(id_payment), {
						payment_date: new Date(),
						have_dte: true,
						dte: {
							codigoGeneracion: dteResponse['codigoGeneracion'],
							selloRecibido: dteResponse['selloRecibido'],
							json: json['dteJson'],
							observaciones: dteResponse['observaciones'],
						}
					});

					if (billing_info.export_required) {
						batch.update(this.db.firestore.collection(`quanto`).doc('dte'), {
							current_fexe_number: dte_number
						});
					} else {
						if (billing_info.ccf_required) {
							batch.update(this.db.firestore.collection(`quanto`).doc('dte'), {
								current_ccfe_number: dte_number
							});
						} else {
							batch.update(this.db.firestore.collection(`quanto`).doc('dte'), {
								current_fe_number: dte_number
							});
						}
					}

					batch.commit().then(data => {
						resolve(true);
					}, err => {
						console.log(err);
						reject();
					});

				}, err => {
					console.log(err);
					reject(err);
				})
			})

		})
	}

	sendDte(billing_info, payment) {

		return new Promise((resolve, reject) => {
			let batch = this.db.firestore.batch();

			batch.update(this.db.firestore.collection(`accounts`).doc(payment.account_key), {
				billing_info: billing_info
			})

			let dte_number;
			if (billing_info.export_required) {
				dte_number = this.auth.quanto_dte.current_fexe_number + 1;
			} else {
				if (billing_info.ccf_required) {
					dte_number = this.auth.quanto_dte.current_ccfe_number + 1;
				} else {
					dte_number = this.auth.quanto_dte.current_fe_number + 1;
				}
			}


			this.dte.getJson(billing_info, payment, dte_number).then(json => {

				console.log(json);

				this.dte.signDte(json).then(dteResponse => {

					batch.update(this.db.firestore.collection(`accounts`).doc(payment.account_key), {
						billing_info: billing_info
					})

					batch.update(this.db.firestore.collection(`payments`).doc(payment.$key), {
						have_dte: true,
						dte: {
							codigoGeneracion: dteResponse['codigoGeneracion'],
							selloRecibido: dteResponse['selloRecibido'],
							json: json['dteJson'],
							observaciones: dteResponse['observaciones'],
						}
					});

					if (billing_info.export_required) {
						batch.update(this.db.firestore.collection(`quanto`).doc('dte'), {
							current_fexe_number: dte_number
						});
					} else {
						if (billing_info.ccf_required) {
							batch.update(this.db.firestore.collection(`quanto`).doc('dte'), {
								current_ccfe_number: dte_number
							});
						} else {
							batch.update(this.db.firestore.collection(`quanto`).doc('dte'), {
								current_fe_number: dte_number
							});
						}
					}

					batch.commit().then(data => {
						resolve(true);
					}, err => {
						console.log(err);
						reject();
					});

				}, err => {
					console.log(err);
					reject(err);
				})
			}, err => {
				console.log(err);
			})
		})
	}

	createExtraPayment(account, total, description) {
		return new Promise((resolve, reject) => {
			total = Number(total);

			this.getDocument(`accounts/${account.$key}/details`, 'payment_method').then(data => {
				let postData = {
					amount: total,
					token: data['token']
				}
				this.http.post(`${environment.api}/payment_with_token`, postData).subscribe(response => {
					let resp = JSON.parse(response['response'].text);
					if (resp['status'] == 'AUTHORIZED') {
						let batch = this.firestore.firestore.batch();
						let payment_key = this.firestore.createId();

						batch.set(this.firestore.firestore.collection(`manual_payments`).doc(payment_key), {
							payment_date: new Date(),
							bin: resp['paymentInformation'].bin,
							account_key: account.$key,
							authorization_code: resp['processorInformation'].approvalCode,
							transaction_id: resp['processorInformation'].transactionId,
							gateway: 'cybersource',
							amount: total,
							description: description
						});

						batch.commit().then(data => {
							resolve(true);
						}, err => {
							console.log(err);
							reject(err);
						});
					} else {
						reject(resp);
					}
				}, err => {
					reject(err);
				})
			})
		})
	}

	/**
	 * It takes an array of products, creates a batch, creates categories, creates items, creates
	 * item_branches, creates subitems, creates subitem_branches, and commits the batch
	 * @param products - Array of objects with the following structure:
	 * @returns A promise
	 */
	createProductsArray(products, inventory_management, out_stock, account) {

		return new Promise((resolve, reject) => {

			let categories = [];
			let items = [];
			let category_key = '';
			let variants_prices = []
			let batch = this.firestore.firestore.batch();
			let branches = [];

			this.getRef(`accounts/${account}/branches`).ref
				.get()
				.then(snapshots_branches => {
					snapshots_branches.forEach(element => {
						let branch = element.data();
						branch.$key = element.id;
						branches.push(branch);
					});

					this.getRef(`accounts/${account}/categories`).ref
						.get()
						.then(snapshots => {
							let cats = [];

							snapshots.forEach(element => {
								let category = element.data();
								category.$key = element.id;
								cats.push(category);
							});

							products.forEach(product => {
								if (product.price == undefined || product.name == undefined) {
									reject('error no hay precio o nombre')
								} else {
									// SE CREA EL ARRAY DE CATEGORIAS Y ASIGNA CATEGORY_KEY A LOS PRODUCTOS
									if (product.category !== undefined) {
										let found_db = cats.findIndex(element => element.name == product.category)

										if (found_db < 0) {
											let found = categories.findIndex(element => element.name == product.category);

											if (found < 0) {
												category_key = this.firestore.createId();
												categories.push({
													name: product.category,
													active: true,
													icon: 'storage',
													color: this.generateRandomColor(),
													$key: category_key
												})
												product['category_key'] = category_key;
											} else {
												category_key = categories[found].$key;
												product['category_key'] = category_key;
											}
										} else {
											product['category_key'] = cats[found_db].$key;
											category_key = cats[found_db].$key;
										}
									}


									if (!product.products_variants) {
										product.price = Number(product.price);
										product.account_key = this.auth.account;
										product.active = true;
										product.have_variants = false;
										product.is_variant = false;
										product.out_stock = out_stock;
										product.inventory_management = inventory_management; //MANEJO DE INVENTARIO
										product.taxes = '';

										//SE ASIGNA LA FOTO DEL PRODUCTO
										if (product.image_url) {
											product['image'] = {};
											product['image']['url'] = product.image_url;
											product['image']['public_id'] = product.image_public_id;
										} else {
											product.image = '';
										}

										items.push(product);
									} else {
										let parent_key = this.firestore.createId();
										let found = items.findIndex(element => element.name == product.name);

										let variants = [];

										if (found > -1) {
											variants = items[found].variants;
											parent_key = items[found].$key;
										} else {

											let parent_item = {
												active: true,
												have_variants: true,
												is_variant: false,
												name: product.name,
												$key: parent_key,
												variants: variants,
												category_key: category_key,
												product: product,
												inventory_management: inventory_management,
												taxes: [],
											}
											if (product.image_url) {
												parent_item['image'] = {};
												parent_item['image']['url'] = product.image_url;
												parent_item['image']['public_id'] = product.image_public_id;
											}

											if (product.description) {
												parent_item['description'] = product.description;
											}
											items.push(parent_item);
										}

										const slug = product.products_variants.split('/');

										let name = '';
										slug.forEach(variant => {
											let found_variant = variants.findIndex(element => element.name == variant.split(':')[0]);

											name += `/${variant.slice(variant.indexOf(':') + 1)}`;

											if (found_variant < 0) {
												variants.push({
													name: variant.split(':')[0],
													options: [variant.slice(variant.indexOf(':') + 1)],
												})
											} else {
												let found_option = variants[found_variant].options.findIndex(element => element == variant.slice(variant.indexOf(':') + 1));
												if (found_option < 0) {
													variants[found_variant].options.push(variant.slice(variant.indexOf(':') + 1))
												}
											}
										});

										let data_variant = {
											variant: name,
											item: product,
											name: `${product.name}|${name}`,
										};

										branches.forEach(element => {
											data_variant[`stock_${element.name.replace(/\s/g, "")}`] = 0;
										});

										variants_prices.push(data_variant);
									}
								}
							});

							//SE CREAN TODAS LAS CATEGORIAS
							categories.forEach(element => {
								batch.set(this.firestore.firestore.collection(`accounts/${account}/categories`).doc(element.$key),
									element
								);
							});

							items.forEach(product => {
								product.account_key = this.auth.account;

								//CUANTO EL PRODUCTO NO ES VARIANTE NI TIENE VARIANTES
								if (!product.have_variants && !product.is_variant) {
									console.log(product);

									let item_key = this.firestore.createId();
									let new_product = JSON.parse(JSON.stringify(product));
									new_product.creation_date = new Date();
									new_product.price = Number(new_product.price).toFixed(2);
									new_product.price = Number(new_product.price);
									new_product.taxes = [];
									new_product.tags = [];
									new_product.is_variant = false;

									if (product.cost) {
										new_product.cost = product.cost ? product.cost : 0;
									}
									if (new_product.code) {
										new_product.code = new_product.code;
									}

									delete new_product.stock;
									console.log(new_product);

									batch.set(this.firestore.firestore.collection(`accounts/${account}/items`).doc(item_key), new_product);

									branches.forEach(element => {
										let branch = element;
										let id_item_branch = this.firestore.createId();

										batch.set(this.firestore.firestore.collection(`accounts/${account}/items_branches`).doc(id_item_branch), {
											branch_key: branch.$key,
											item_key: item_key,
											quantity: product.stock ? product.stock : 0,
											available: true,
											is_variant: false,
											have_variants: false,
											inventory_management: inventory_management
										});
									})
								} else {
									let item_key = this.firestore.createId();

									batch.set(this.firestore.firestore.collection(`accounts/${account}/items`).doc(item_key), product);

									branches.forEach(element => {
										let branch = element;
										let id_item_branch = this.firestore.createId();
										batch.set(this.firestore.firestore.collection(`accounts/${account}/items_branches`).doc(id_item_branch), {
											available: true,
											branch_key: branch.$key,
											have_variants: true,
											is_variant: false,
											item_key: item_key,
											inventory_management: inventory_management,
											taxes: [],
											tags: []
										});
									});

									let all_variants = [];

									product.variants.forEach(variant => {

										if (all_variants.length > 0) {
											let new_all_variants = [];

											all_variants.forEach(response_variant => {
												variant.options.forEach(option => {

													const found = items.findIndex(_element => _element.name == `${response_variant.name} / ${option}`);

													if (found > -1) {
														new_all_variants.push(items[found]);
													} else {
														new_all_variants.push({
															name: `${response_variant.name} / ${option}`,
															branches: JSON.parse(JSON.stringify(branches)),
															item: product.product,
															parent: product
														})
													}

												});

											});
											all_variants = new_all_variants;
										} else {
											variant.options.forEach((option, i) => {
												const found = items.findIndex(_element => _element.name == option);
												if (found > -1) {
													all_variants.push(items[found]);
												} else {
													all_variants.push({
														name: option,
														branches: JSON.parse(JSON.stringify(branches)),
														item: product.product,
														parent: product
													})
												}
											});

										}
									})

									all_variants.forEach(element => {
										let item = element.item;

										let subitem_key = this.firestore.createId();
										let found_price = variants_prices.findIndex(_element => _element.name == `${element.parent.name}|/${element.name}`);

										if (found_price < 0) {
											let subitem = element.item;
											subitem['name'] = element.name;
											subitem['out_stock'] = out_stock;
											subitem['parent_key'] = item_key;
											subitem['price'] = 0;
											subitem['is_variant'] = true;
											subitem['creation_date'] = new Date();
											subitem['inventory_management'] = inventory_management;
											subitem['active'] = true;

											if (subitem.category) {
												delete subitem.category;
											}
											delete subitem.variant;
											delete subitem.parent;

											if (subitem.category) {

											}

											batch.set(this.firestore.firestore.collection(`accounts/${account}/items`).doc(subitem_key), subitem);

											branches.forEach(element => {
												let branch = element;
												let id_subtime_branch = this.firestore.createId();


												batch.set(this.firestore.firestore.collection(`accounts/${account}/items_branches`).doc(id_subtime_branch), {
													available: true,
													branch_key: branch.$key,
													is_variant: true,
													item_key: subitem_key,
													out_stock: out_stock,
													parent_key: item_key,
													quantity: subitem.stock ? subitem.stock : 0,
													inventory_management: inventory_management
												});

											});

										} else {

											let subitem = variants_prices[found_price].item

											subitem['name'] = element.name;
											subitem['out_stock'] = out_stock;
											subitem['parent_key'] = item_key;
											subitem['price'] = Number(subitem['price']);
											subitem['inventory_management'] = inventory_management;
											subitem['is_variant'] = true;
											subitem['active'] = true;
											subitem['creation_date'] = new Date();

											subitem['active'] = true;

											if (subitem.category) {
												delete subitem.category;
											}
											delete subitem.variant;
											delete subitem.parent;

											if (subitem.category) {

											}

											batch.set(this.firestore.firestore.collection(`accounts/${account}/items`).doc(subitem_key), subitem);

											branches.forEach(element => {
												let branch = element;
												let id_subtime_branch = this.firestore.createId();

												batch.set(this.firestore.firestore.collection(`accounts/${account}/items_branches`).doc(id_subtime_branch), {
													available: true,
													branch_key: branch.$key,
													is_variant: true,
													item_key: subitem_key,
													out_stock: out_stock,
													parent_key: item_key,
													quantity: subitem.stock ? subitem.stock : 0,
													inventory_management: inventory_management
												});

											});
										}
									});

								}
							});

							batch.commit().then(data => {
								alert('PRODUCTOS SUBIDOS CORRECTAMENTE!!!! 👍🏻')
								resolve(true);
							}, err => {
								console.log(err);
								reject();
							})
						})
				})


		});
	}

	/**
	 * It generates a random color
	 * @returns A random color in hexadecimal format.
	 */
	generateRandomColor() {
		var letters = '0123456789ABCDEF';
		var color = '#';
		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	createLink(data) {
		return new Promise((resolve, reject) => {
			this.addDocument(`payment_links`, data).then(data => {
				resolve(true);
			})
		})
	}
}
