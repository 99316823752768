import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { Storage } from '@ionic/storage';
import { ApiService } from 'src/app/services/api/api.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { ComponentsService } from 'src/app/services/components/components.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private auth: AuthService,
        public storage: Storage,
        private api: ApiService,
        public angularAuth: AngularFireAuth,
        public components: ComponentsService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

        return new Observable<boolean>((observer) => {
            this.auth.getAuthState().subscribe(user => {

                if (user && user.uid) {

                    this.storage.get('token').then((response) => {
                        console.log(response);
                    });
                    observer.next(true);
                    observer.complete();
                } else {

                    console.log('logout');
                    this.router.navigate(['/login']);
                    observer.next(false);
                    observer.complete();
                }
            });
        });
    }
}




