import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';
import { xml2js } from 'xml-js';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import * as writtenNumber from 'written-number';
import { ComponentsService } from '../components/components.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
    providedIn: 'root'
})
export class DteService {

    constructor(
        private http: HttpClient,
        public auth: AuthService,
        public components: ComponentsService
    ) {
    }

    validateUser(user, password) {
        return new Promise(async (resolve, reject) => {
            this.http.post(`${environment.mh.url}/seguridad/auth?user=${user}&pwd=${password}`, {}).subscribe(response => {
                if (response['status'] == 'OK') {
                    resolve(true);
                } else {
                    reject();
                }
            }, err => {
                reject(err);
            })
        })
    }

    async signDte(filter: any, type = 'reception') {

        return new Promise(async (resolve, reject) => {
            const certificado = await this.recuperarCertificado(filter);
            if (certificado) {

                let postData = {
                    private_key: certificado.privateKey.encodied._text,
                    jsonData: filter.dteJson,
                }

                this.http.post(`${environment.api}/sign_json`, postData).subscribe(response => {
                    let signed_json = response['token'];

                    let authPostData = {
                        user: this.auth.quanto_dte.nit,
                        pwd: this.auth.quanto_dte.passwordMH
                    };

                    this.http.post(`${environment.mh.url}/seguridad/auth?user=${authPostData.user}&pwd=${authPostData.pwd}`, {}).subscribe(response => {
                        const headers = {
                            Authorization: `${response['body']['token']}`,
                        };

                        if (type == 'reception') {

                            this.http.post(`${environment.mh.url}/fesv/recepciondte`, {
                                "ambiente": environment.mh.environment,
                                "idEnvio": "01",
                                "version": filter.dteJson.identificacion.version,
                                "tipoDte": filter.dteJson.identificacion.tipoDte,
                                "documento": signed_json,
                                "codigoGeneracion": filter.dteJson.identificacion.codigoGeneracion
                            }, { headers: headers }).subscribe(response => {
                                resolve(response);
                            }, err => {
                                reject(err);
                            })
                        } else if (type == 'invalidation') {
                            this.http.post(`${environment.mh.url}/fesv/anulardte`, {
                                "ambiente": environment.mh.environment,
                                "idEnvio": "02",
                                "version": filter.dteJson.identificacion.version,
                                "documento": signed_json,
                            }, { headers: headers }).subscribe(response => {
                                resolve(response);
                            }, err => {
                                console.log(err);
                                reject(err.error.descripcionMsg);
                            })
                        } else if (type == 'contingency') {
                            this.http.post(`${environment.mh.url}/fesv/contingencia`, {
                                "nit": this.auth.account_data.fiscal.nit,
                                "documento": signed_json,
                            }, { headers: headers }).subscribe(response => {
                                resolve(response);
                            }, err => {
                                reject(err);
                            })
                        }



                    }, err => {
                        reject(err);
                    });

                }, err => {
                    reject(err);

                })
            } else {
                console.log('La contraseña no es válida o el certificado no se pudo recuperar');
                reject('La contraseña no es válida o el certificado no se pudo recuperar');
                // La contraseña no es válida o el certificado no se pudo recuperar
            }
        })
    }

    encrypt(p: string): string {
        const diget = CryptoJS.algo.SHA512.create();
        diget.update(p);
        const encodedhash = diget.finalize();
        return this.bytesToHex(encodedhash);
    }

    private bytesToHex(hash: any): string {
        let hexString = '';
        for (let i = 0; i < hash.words.length; i++) {
            const word = hash.words[i];
            const byteLen = 4;
            for (let j = 0; j < byteLen; j++) {
                const byte = (word >> (24 - j * 8)) & 0xff;
                const hex = byte.toString(16).padStart(2, '0');
                hexString += hex;
            }
        }
        return hexString;
    }

    async recuperarCertificado(filter: any): Promise<any | null> {
        try {
            const xmlOptions = { compact: true, spaces: 4 }; // Opciones para la conversión XML a JS
            const crypto = this.encrypt(filter.passwordPri);
            const contenido = this.auth.quanto_dte.certified;
            const certificadoObj: any = xml2js(contenido, xmlOptions);

            const certificado: any = certificadoObj.CertificadoMH;

            if (certificado.privateKey.clave._text === crypto) {
                return certificado;
            } else {
                console.log(`Password no válido: ${certificado.nit._text}`);
                return null;
            }
        } catch (error) {
            console.log(error.message);
            throw error;
        }
    }

    async getCertifiedData(certified: any): Promise<any | null> {
        try {
            const xmlOptions = { compact: true, spaces: 4 }; // Opciones para la conversión XML a JS
            const contenido = certified;
            const certificadoObj: any = xml2js(contenido, xmlOptions);

            const certificado: any = certificadoObj.CertificadoMH;

            return certificado;

        } catch (error) {
            console.log(error.message);
            throw error;
        }
    }

    async getCertifiedPasswordValidation(certified: any, password): Promise<any | null> {
        try {
            const xmlOptions = { compact: true, spaces: 4 }; // Opciones para la conversión XML a JS
            const crypto = this.encrypt(password);
            const contenido = certified;
            const certificadoObj: any = xml2js(contenido, xmlOptions);

            const certificado: any = certificadoObj.CertificadoMH;

            console.log(certificado.privateKey.clave._text);
            console.log(crypto);

            if (certificado.privateKey.clave._text === crypto) {
                return certificado;
            } else {
                console.log(`Password no válido: ${certificado.nit._text}`);
                return null;
            }

        } catch (error) {
            console.log(error.message);
            throw error;
        }
    }

    padZero(num: number): string {
        return num < 10 ? `0${num}` : `${num}`;
    }

    padLeadingZeros(num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        if (!isNaN(Number(s))) {
            return s;
        }
    }

    getJson(billing_info, payment, dte_number) {

        return new Promise((resolve, reject) => {
            const currentTime = new Date();
            const hours = this.padZero(currentTime.getHours());
            const minutes = this.padZero(currentTime.getMinutes());

            const seconds = this.padZero(currentTime.getSeconds());
            let formattedTime = `${hours}:${minutes}:${seconds}`;

            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = this.padZero(currentDate.getMonth() + 1); // Los meses en JavaScript son base 0
            const day = this.padZero(currentDate.getDate());

            let formattedDate = `${year}-${month}-${day}`;
            console.log(formattedDate);


            let typeDte;
            let version;
            let control_number;

            if (billing_info.export_required) {
                typeDte = '11';
                version = 1;
                control_number = `DTE-${typeDte}-M001P001-${this.padLeadingZeros(String(dte_number), 15)}`;
            } else {
                if (billing_info.ccf_required) {
                    typeDte = '03';
                    version = 3;
                    // 2DO hay que cambia al M001
                    control_number = `DTE-${typeDte}-M001P001-${this.padLeadingZeros(String(dte_number), 15)}`;
                } else {
                    typeDte = '01';
                    version = 1
                    control_number = `DTE-${typeDte}-M001P001-${this.padLeadingZeros(String(dte_number), 15)}`;
                }

            }

            let generation_code = uuidv4().toUpperCase();

            payment.total = this.components.formatCurrency(payment.amount).replace('$', '');

            let total_in_words = writtenNumber(payment.amount, { lang: 'es' })

            const partes = payment.amount.toString().split('.');

            const dolares = writtenNumber(partes[0], { lang: 'es' }).toUpperCase();
            const centavos = writtenNumber(partes[1] || '0', { lang: 'es' }).toUpperCase();
            total_in_words = `${dolares.toUpperCase()} DÓLARES CON ${centavos.toUpperCase()} CENTAVOS`;

            if (partes[1] == 0 || partes[1] == undefined) {
                total_in_words = `${dolares.toUpperCase()} DÓLARES`;
            }

            let counter = 1;

            let json = {
                "nit": this.auth.quanto_dte.nit,
                "active": true,
                "passwordPri": this.auth.quanto_dte.passwordCert,
                "dteJson": {
                    "identificacion": {},
                    "emisor": {},
                    "receptor": null,
                    "cuerpoDocumento": [],
                    "resumen": {},
                    "documentoRelacionado": null,
                    "otrosDocumentos": null,
                    "ventaTercero": null,
                    "extension": null,
                    "apendice": null
                }
            }

            if (billing_info.export_required) {

                delete json.dteJson.extension;
                delete json.dteJson.documentoRelacionado;

                json.dteJson.identificacion = {
                    "version": version,
                    "ambiente": environment.mh.environment,
                    "tipoDte": typeDte,
                    "numeroControl": control_number,
                    "codigoGeneracion": generation_code,
                    "tipoModelo": 1,
                    "tipoOperacion": 1,
                    "tipoContingencia": null,
                    "fecEmi": formattedDate,
                    "horEmi": formattedTime,
                    "motivoContigencia": null,
                    "tipoMoneda": "USD"
                }

                json.dteJson.emisor = {
                    "nit": this.auth.quanto_dte.nit,
                    "nrc": this.auth.quanto_dte.nrc,
                    "nombre": this.auth.quanto_dte.name,
                    "nombreComercial": this.auth.quanto_dte.commercial_name,
                    "codActividad": this.auth.quanto_dte.activity_code,
                    "descActividad": this.auth.quanto_dte.activity_name,
                    // "codActividad": '62010',
                    // "descActividad": 'Programación informática',
                    "tipoEstablecimiento": '02',
                    "direccion": {
                        "departamento": '06',
                        "municipio": '14',
                        "complemento": 'BLVD HIPODROMO COL SAN BENITO #582'
                    },
                    "telefono": '220305020',
                    "correo": 'info@quantopos.com',
                    "codEstableMH": null,
                    "codEstable": null,
                    "codPuntoVentaMH": null,
                    "codPuntoVenta": null,
                    "regimen": null,
                    "recintoFiscal": null,
                    "tipoItemExpor": 2
                }

                json.dteJson.resumen = {
                    "totalGravada": payment.amount,
                    "porcentajeDescuento": 0,
                    "totalDescu": 0,
                    "montoTotalOperacion": payment.amount,
                    "totalNoGravado": 0,
                    "totalPagar": Number(payment.amount),
                    "totalLetras": total_in_words,
                    "condicionOperacion": 1,
                    "pagos": [],
                    "numPagoElectronico": null,
                    "codIncoterms": null,
                    "descIncoterms": null,
                    "observaciones": null,
                    "flete": 0,
                    "seguro": 0,
                    "descuento": 0,
                }

                json.dteJson.receptor = {
                    "nombre": billing_info.name,
                    "codPais": String(billing_info.country.code),
                    "nombrePais": billing_info.country.value,
                    "complemento": billing_info.address,
                    "tipoDocumento": "37",
                    "numDocumento": billing_info.document,
                    "nombreComercial": billing_info.name,
                    "tipoPersona": 1,
                    "descActividad": billing_info.activity.value,
                    "telefono": billing_info.phone,
                    "correo": billing_info.email,
                }

                if (payment.description == 'membership payment' || payment.description == 'membership payment with token' || payment.description == 'First membership payment') {
                    json.dteJson.cuerpoDocumento.push({
                        "numItem": counter++,
                        "cantidad": 1,
                        "codigo": null,
                        "uniMedida": 59,
                        "descripcion": `Membrecia ${payment.license.membership.type} ${payment.license.membership.name}`,
                        "precioUni": payment.amount,
                        "montoDescu": 0,
                        "ventaGravada": payment.amount,
                        "tributos": [
                            "C3"
                        ],
                        "noGravado": 0
                    })
                } else if (payment.description == 'Membership upgrade') {
                    json.dteJson.cuerpoDocumento.push({
                        "numItem": counter++,
                        "cantidad": 1,
                        "codigo": null,
                        "uniMedida": 59,
                        "descripcion": `Actualización de membresia`,
                        "precioUni": payment.amount,
                        "montoDescu": 0,
                        "ventaGravada": payment.amount,
                        "tributos": [
                            "C3"
                        ],
                        "noGravado": 0
                    })
                }

            } else {

                json.dteJson.identificacion = {
                    "version": version,
                    "ambiente": environment.mh.environment,
                    "tipoDte": typeDte,
                    "numeroControl": control_number,
                    "codigoGeneracion": generation_code,
                    "tipoModelo": 1,
                    "tipoOperacion": 1,
                    "tipoContingencia": null,
                    "motivoContin": null,
                    "fecEmi": formattedDate,
                    "horEmi": formattedTime,
                    "tipoMoneda": "USD"
                }

                json.dteJson.emisor = {
                    "nit": this.auth.quanto_dte.nit,
                    "nrc": this.auth.quanto_dte.nrc,
                    "nombre": this.auth.quanto_dte.name,
                    "nombreComercial": this.auth.quanto_dte.commercial_name,
                    "codActividad": this.auth.quanto_dte.activity_code,
                    "descActividad": this.auth.quanto_dte.activity_name,
                    // "codActividad": '62010',
                    // "descActividad": 'Programación informática',
                    "tipoEstablecimiento": '02',
                    "direccion": {
                        "departamento": '06',
                        "municipio": '14',
                        "complemento": 'BLVD HIPODROMO COL SAN BENITO #582'
                    },
                    "telefono": '220305020',
                    "correo": 'info@quantopos.com',
                    "codEstableMH": null,
                    "codEstable": null,
                    "codPuntoVentaMH": null,
                    "codPuntoVenta": null
                }

                if (billing_info.ccf_required) {
                    json.dteJson.resumen = {
                        "totalNoSuj": 0,
                        "totalExenta": 0,
                        "totalGravada": this.components.fixed(payment.amount - billing_info.taxes),
                        "subTotalVentas": this.components.fixed(payment.amount - billing_info.taxes),
                        "descuNoSuj": 0,
                        "descuExenta": 0,
                        "descuGravada": 0,
                        "porcentajeDescuento": 0,
                        "totalDescu": 0,
                        "tributos": [{
                            "codigo": "20",
                            "descripcion": "Impuesto al Valor Agregado 13%",
                            "valor": this.components.fixed(billing_info.taxes)
                        }],
                        "subTotal": this.components.fixed(payment.amount - billing_info.taxes),
                        "ivaPerci1": 0,
                        "ivaRete1": 0,
                        "reteRenta": 0,
                        "montoTotalOperacion": this.components.fixed(payment.amount),
                        "totalNoGravado": 0,
                        "totalPagar": this.components.fixed(payment.amount),
                        "totalLetras": total_in_words,
                        "saldoFavor": 0,
                        "condicionOperacion": 1,
                        "pagos": [],
                        "numPagoElectronico": null
                    }
                    json.dteJson.receptor = {
                        "nrc": billing_info.nrc,
                        "nit": billing_info.nit,
                        "nombre": billing_info.name,
                        "codActividad": String(billing_info.activity.code),
                        "descActividad": billing_info.activity.value,
                        "correo": billing_info.email,
                        "nombreComercial": billing_info.name,
                        "direccion": {
                            "departamento": billing_info.depto.code,
                            "municipio": billing_info.munic.code,
                            "complemento": billing_info.address,
                        },
                        "telefono": billing_info.phone
                    }

                    json.dteJson.cuerpoDocumento.push({
                        "numItem": counter++,
                        "tipoItem": 2,
                        "numeroDocumento": null,
                        "codigo": null,
                        "codTributo": null,
                        "descripcion": `Membresía Quanto`,
                        "cantidad": 1,
                        "uniMedida": 59,
                        "precioUni": this.components.fixed(Number(payment.amount - billing_info.taxes)),
                        "montoDescu": 0,
                        "ventaNoSuj": 0,
                        "ventaExenta": 0,
                        "ventaGravada": this.components.fixed(Number(payment.amount - billing_info.taxes)),
                        "tributos": ["20"],
                        "psv": 0,
                        "noGravado": 0
                    });

                    // if (payment.description == 'membership payment' || payment.description == 'membership payment with token' || payment.description == 'First membership payment') {
                    //     json.dteJson.cuerpoDocumento.push({
                    //         "numItem": counter++,
                    //         "tipoItem": 2,
                    //         "numeroDocumento": null,
                    //         "codigo": null,
                    //         "codTributo": null,
                    //         "descripcion": `Membrecia ${payment.license.membership.type} ${payment.license.membership.name}`,
                    //         "cantidad": 1,
                    //         "uniMedida": 59,
                    //         "precioUni": Number(payment.license.membership.price),
                    //         "montoDescu": 0,
                    //         "ventaNoSuj": 0,
                    //         "ventaExenta": 0,
                    //         "ventaGravada": Number(payment.license.membership.price),
                    //         "tributos": ["20"],
                    //         "psv": 0,
                    //         "noGravado": 0
                    //     });

                    // } else if (payment.description == 'Membership upgrade') {
                    //     json.dteJson.cuerpoDocumento.push({
                    //         "numItem": counter++,
                    //         "tipoItem": 2,
                    //         "numeroDocumento": null,
                    //         "codigo": null,
                    //         "codTributo": null,
                    //         "descripcion": `Actualizacion de membresia`,
                    //         "cantidad": 1,
                    //         "uniMedida": 59,
                    //         "precioUni": Number(payment.subtotal),
                    //         "montoDescu": 0,
                    //         "ventaNoSuj": 0,
                    //         "ventaExenta": 0,
                    //         "ventaGravada": Number(payment.subtotal),
                    //         "tributos": ["20"],
                    //         "psv": 0,
                    //         "noGravado": 0
                    //     })
                    // } else {

                    // }
                } else {
                    json.dteJson.resumen = {
                        "totalNoSuj": 0,
                        "totalExenta": 0,
                        "totalGravada": this.components.fixed(payment.amount),
                        "subTotalVentas": this.components.fixed(payment.amount),
                        "descuNoSuj": 0,
                        "descuExenta": 0,
                        "descuGravada": 0,
                        "porcentajeDescuento": 0,
                        "totalDescu": 0,
                        "tributos": null,
                        "subTotal": this.components.fixed(payment.amount),
                        "ivaRete1": 0,
                        "reteRenta": 0,
                        "montoTotalOperacion": this.components.fixed(payment.amount),
                        "totalNoGravado": 0,
                        "totalPagar": this.components.fixed(payment.amount),
                        "totalLetras": total_in_words,
                        "totalIva": this.components.fixed(billing_info.taxes),
                        "condicionOperacion": 1,
                        "pagos": [],
                        "saldoFavor": 0,
                        "numPagoElectronico": null
                    }

                    json.dteJson.receptor = {
                        "tipoDocumento": null,
                        "numDocumento": null,
                        "nombre": billing_info.name, //Opcional null
                        "correo": billing_info.email,
                        "nrc": null, //Opcional null
                        "telefono": null, //Opcional null
                        "codActividad": null, //Opcional null
                        "descActividad": null, //Opcional null
                        "direccion": null, //Opcional null
                    }

                    if (payment.license.taxes == undefined) {
                        payment.license.taxes = this.components.fixed(((13 / 100 + 1) * payment.license.membership.price) - Number(payment.license.membership.price))
                    }

                    json.dteJson.cuerpoDocumento.push({
                        "numItem": counter++,
                        "tipoItem": 2,
                        "cantidad": 1,
                        "codigo": null,
                        "uniMedida": 59,
                        "descripcion": `Membresía Quanto`,
                        "precioUni": this.components.fixed(payment.amount),
                        "montoDescu": 0,
                        "ventaNoSuj": 0,
                        "ventaExenta": 0,
                        "ventaGravada": this.components.fixed(payment.amount),
                        "tributos": null,
                        "psv": 0,
                        "noGravado": 0,
                        "numeroDocumento": null,
                        "codTributo": null,
                        "ivaItem": this.components.fixed(payment.total_taxes)
                    })
                }
            }

            // if (payment.extras !== undefined) {
            //     payment.extras.forEach(extra => {

            //         if (billing_info.export_required) {
            //             json.dteJson.cuerpoDocumento.push({
            //                 "numItem": counter++,
            //                 "cantidad": 1,
            //                 "codigo": null,
            //                 "uniMedida": 59,
            //                 "precioUni": Number(extra.price),
            //                 "montoDescu": 0,
            //                 "ventaGravada": Number(extra.price),
            //                 "tributos": [
            //                     "C3"
            //                 ],
            //                 "noGravado": 0
            //             })
            //         } else {
            //             if (billing_info.ccf_required) {

            //                 extra.taxes = this.components.fixed(((13 / 100 + 1) * extra.price) - Number(extra.price))

            //                 json.dteJson.cuerpoDocumento.push({
            //                     "numItem": counter++,
            //                     "tipoItem": 2,
            //                     "numeroDocumento": null,
            //                     "codigo": null,
            //                     "codTributo": null,
            //                     "descripcion": `Extra ${extra.service}`,
            //                     "cantidad": 1,
            //                     "uniMedida": 59,
            //                     "precioUni": Number(extra.price),
            //                     "montoDescu": 0,
            //                     "ventaNoSuj": 0,
            //                     "ventaExenta": 0,
            //                     "ventaGravada": Number(extra.price),
            //                     "tributos": ["20"],
            //                     "psv": 0,
            //                     "noGravado": 0
            //                 });
            //             } else {
            //                 json.dteJson.cuerpoDocumento.push({
            //                     "numItem": counter++,
            //                     "tipoItem": 2,
            //                     "cantidad": 1,
            //                     "codigo": null,
            //                     "uniMedida": 59,
            //                     "descripcion": `Extra ${extra.service}`,
            //                     "precioUni": this.components.fixed(extra.price + extra.taxes),
            //                     "montoDescu": 0,
            //                     "ventaNoSuj": 0,
            //                     "ventaExenta": 0,
            //                     "ventaGravada": this.components.fixed(extra.price + extra.taxes),
            //                     "tributos": null,
            //                     "psv": 0,
            //                     "noGravado": 0,
            //                     "numeroDocumento": null,
            //                     "codTributo": null,
            //                     "ivaItem": this.components.fixed(extra.taxes)
            //                 })
            //             }
            //         }
            //     });
            // }

            json.dteJson.resumen['pagos'].push({
                "montoPago": payment.amount,
                "referencia": null,
                "codigo": '02',
                "plazo": null,
                "periodo": null
            })

            resolve(json)
        })
    }

    getNCJson(dte, dte_number) {
        return new Promise((resolve, reject) => {
            const currentTime = new Date();
            const hours = this.padZero(currentTime.getHours());
            const minutes = this.padZero(currentTime.getMinutes());

            const seconds = this.padZero(currentTime.getSeconds());
            let formattedTime = `${hours}:${minutes}:${seconds}`;

            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = this.padZero(currentDate.getMonth() + 1); // Los meses en JavaScript son base 0
            const day = this.padZero(currentDate.getDate());

            let formattedDate = `${year}-${month}-${day}`;
            let generation_code = uuidv4().toUpperCase();

            let resumen = dte.json.resumen;
            delete resumen.pagos;
            delete resumen.numPagoElectronico;
            delete resumen.porcentajeDescuento;
            delete resumen.totalNoGravado;
            delete resumen.saldoFavor;
            delete resumen.totalPagar;

            let cuerpoDocumento = dte.json.cuerpoDocumento;

            cuerpoDocumento.forEach(element => {
                delete element.noGravado;
                delete element.psv;
                element.numeroDocumento = dte.json.identificacion.codigoGeneracion;
            });
            let json = {
                "nit": this.auth.quanto_dte.nit,
                "active": true,
                "passwordPri": this.auth.quanto_dte.passwordCert,
                "dteJson": {
                    "identificacion": {
                        "version": 3,
                        "ambiente": environment.mh.environment,
                        "tipoDte": '05',
                        "numeroControl": `DTE-05-M001P001-${this.padLeadingZeros(String(dte_number), 15)}`,
                        "codigoGeneracion": generation_code,
                        "tipoModelo": 1,
                        "tipoOperacion": 1,
                        "tipoContingencia": null,
                        "motivoContin": null,
                        "fecEmi": formattedDate,
                        "horEmi": formattedTime,
                        "tipoMoneda": "USD"
                    },
                    "documentoRelacionado": [
                        {
                            "tipoDocumento": "03",
                            "tipoGeneracion": 2,
                            "numeroDocumento": dte.json.identificacion.codigoGeneracion,
                            "fechaEmision": dte.json.identificacion.fecEmi
                        }
                    ],
                    "emisor": {
                        "nit": this.auth.quanto_dte.nit,
                        "nrc": this.auth.quanto_dte.nrc,
                        "nombre": this.auth.quanto_dte.name,
                        "codActividad": this.auth.quanto_dte.activity_code,
                        "descActividad": this.auth.quanto_dte.activity_name,
                        "nombreComercial": this.auth.quanto_dte.commercial_name,
                        // "codActividad": '62010',
                        // "descActividad": 'Programación informática',
                        "tipoEstablecimiento": '02',
                        "direccion": {
                            "departamento": '06',
                            "municipio": '14',
                            "complemento": 'BLVD HIPODROMO COL SAN BENITO #582'
                        },
                        "telefono": '220305020',
                        "correo": 'info@quantopos.com',
                    },
                    "receptor": {
                        "nit": dte.json.receptor.nit,
                        "nrc": dte.json.receptor.nrc,
                        "nombre": dte.json.receptor.nombre,
                        "codActividad": dte.json.receptor.codActividad,
                        "descActividad": dte.json.receptor.descActividad,
                        "nombreComercial": dte.json.receptor.nombreComercial,
                        "direccion": dte.json.receptor.direccion,
                        "telefono": dte.json.receptor.telefono,
                        "correo": dte.json.receptor.correo,
                    },
                    "ventaTercero": null,
                    "cuerpoDocumento": dte.json.cuerpoDocumento,
                    "resumen": dte.json.resumen,
                    "extension": null,
                    "apendice": null
                }
            }

            json.dteJson

            resolve(json)
        })
    }

    getInvalidationJson(payment, dte_number) {
        return new Promise((resolve, reject) => {
            const currentTime = new Date();
            const hours = this.padZero(currentTime.getHours());
            const minutes = this.padZero(currentTime.getMinutes());

            const seconds = this.padZero(currentTime.getSeconds());
            let formattedTime = `${hours}:${minutes}:${seconds}`;

            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = this.padZero(currentDate.getMonth() + 1); // Los meses en JavaScript son base 0
            const day = this.padZero(currentDate.getDate());

            let formattedDate = `${year}-${month}-${day}`;

            let generation_code = uuidv4().toUpperCase();

            console.log(payment);

            let json = {
                "nit": this.auth.quanto_dte.nit,
                "active": true,
                "passwordPri": this.auth.quanto_dte.passwordCert,
                "dteJson": {
                    "identificacion": {
                        "version": 2,
                        "ambiente": environment.mh.environment,
                        "codigoGeneracion": generation_code,
                        "fecAnula": formattedDate,
                        "horAnula": formattedTime,
                    },
                    "emisor": {
                        "nit": this.auth.quanto_dte.nit,
                        "nombre": this.auth.quanto_dte.name,
                        "tipoEstablecimiento": '02',
                        "telefono": '220305020',
                        "correo": 'info@quantopos.com',
                        "codEstable": 'M001',
                        "codPuntoVenta": 'P001',
                        "nomEstablecimiento": 'Quanto'
                    },
                    "documento": {
                        "tipoDte": payment.json.identificacion.tipoDte,
                        "codigoGeneracion": payment.json.identificacion.codigoGeneracion,
                        "selloRecibido": payment.selloRecibido,
                        "numeroControl": payment.json.identificacion.numeroControl,
                        "fecEmi": payment.json.identificacion.fecEmi,
                        "montoIva": payment.total_taxes,
                        "codigoGeneracionR": null, //Esto solamente si se reemplaza por algun otro
                        "tipoDocumento": '36',
                        "numDocumento": '06140605841099',
                        "nombre": payment.json.receptor.nombre,
                    },
                    "motivo": {
                        "tipoAnulacion": 2,
                        "motivoAnulacion": 'Error en las fechas de envio',
                        "nombreResponsable": 'Jorge Jovel',
                        "tipDocResponsable": '13',
                        "numDocResponsable": '047287847',
                        "nombreSolicita": 'Erik Renderos',
                        "tipDocSolicita": '13',
                        "numDocSolicita": "056021834"
                    }
                }
            }

            resolve(json);
        })
    }

    getHardwareJson(billing_info, payment, items, dte_number) {

        return new Promise((resolve, reject) => {
            const currentTime = new Date();
            const hours = this.padZero(currentTime.getHours());
            const minutes = this.padZero(currentTime.getMinutes());

            const seconds = this.padZero(currentTime.getSeconds());
            let formattedTime = `${hours}:${minutes}:${seconds}`;

            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = this.padZero(currentDate.getMonth() + 1); // Los meses en JavaScript son base 0
            const day = this.padZero(currentDate.getDate());

            let formattedDate = `${year}-${month}-${day}`;

            let typeDte;
            let version;
            let control_number;

            if (billing_info.export_required) {
                typeDte = '11';
                version = 1;
                control_number = `DTE-${typeDte}-M001P001-${this.padLeadingZeros(String(dte_number), 15)}`;
            } else {
                if (billing_info.ccf_required) {
                    typeDte = '03';
                    version = 3;
                    control_number = `DTE-${typeDte}-M001P001-${this.padLeadingZeros(String(dte_number), 15)}`;
                } else {
                    typeDte = '01';
                    version = 1
                    control_number = `DTE-${typeDte}-M001P001-${this.padLeadingZeros(String(dte_number), 15)}`;
                }

            }

            let generation_code = uuidv4().toUpperCase();

            payment.total = this.components.formatCurrency(payment.amount).replace('$', '');

            let total_in_words = writtenNumber(payment.amount, { lang: 'es' })

            const partes = payment.amount.toString().split('.');

            const dolares = writtenNumber(partes[0], { lang: 'es' }).toUpperCase();
            const centavos = writtenNumber(partes[1] || '0', { lang: 'es' }).toUpperCase();
            total_in_words = `${dolares.toUpperCase()} DÓLARES CON ${centavos.toUpperCase()} CENTAVOS`;

            if (partes[1] == 0 || partes[1] == undefined) {
                total_in_words = `${dolares.toUpperCase()} DÓLARES`;
            }

            let counter = 1;

            let json = {
                "nit": this.auth.quanto_dte.nit,
                "active": true,
                "passwordPri": this.auth.quanto_dte.passwordCert,
                "dteJson": {
                    "identificacion": {},
                    "emisor": {},
                    "receptor": null,
                    "cuerpoDocumento": [],
                    "resumen": {},
                    "documentoRelacionado": null,
                    "otrosDocumentos": null,
                    "ventaTercero": null,
                    "extension": null,
                    "apendice": null
                }
            }

            if (billing_info.export_required) {

                delete json.dteJson.extension;
                delete json.dteJson.documentoRelacionado;

                json.dteJson.identificacion = {
                    "version": version,
                    "ambiente": environment.mh.environment,
                    "tipoDte": typeDte,
                    "numeroControl": control_number,
                    "codigoGeneracion": generation_code,
                    "tipoModelo": 1,
                    "tipoOperacion": 1,
                    "tipoContingencia": null,
                    "fecEmi": formattedDate,
                    "horEmi": formattedTime,
                    "motivoContigencia": null,
                    "tipoMoneda": "USD"
                }

                json.dteJson.emisor = {
                    "nit": this.auth.quanto_dte.nit,
                    "nrc": this.auth.quanto_dte.nrc,
                    "nombre": this.auth.quanto_dte.name,
                    "nombreComercial": this.auth.quanto_dte.commercial_name,
                    "codActividad": this.auth.quanto_dte.activity_code,
                    "descActividad": this.auth.quanto_dte.activity_name,
                    // "codActividad": '62010',
                    // "descActividad": 'Programación informática',
                    "tipoEstablecimiento": '02',
                    "direccion": {
                        "departamento": '06',
                        "municipio": '14',
                        "complemento": 'BLVD HIPODROMO COL SAN BENITO #582'
                    },
                    "telefono": '220305020',
                    "correo": 'info@quantopos.com',
                    "codEstableMH": null,
                    "codEstable": null,
                    "codPuntoVentaMH": null,
                    "codPuntoVenta": null,
                    "regimen": null,
                    "recintoFiscal": null,
                    "tipoItemExpor": 2
                }

                json.dteJson.resumen = {
                    "totalGravada": payment.amount,
                    "porcentajeDescuento": 0,
                    "totalDescu": 0,
                    "montoTotalOperacion": payment.amount,
                    "totalNoGravado": 0,
                    "totalPagar": Number(payment.amount),
                    "totalLetras": total_in_words,
                    "condicionOperacion": 1,
                    "pagos": [],
                    "numPagoElectronico": null,
                    "codIncoterms": null,
                    "descIncoterms": null,
                    "observaciones": null,
                    "flete": 0,
                    "seguro": 0,
                    "descuento": 0,
                }

                json.dteJson.receptor = {
                    "nombre": billing_info.name,
                    "codPais": String(billing_info.country.code),
                    "nombrePais": billing_info.country.value,
                    "complemento": billing_info.address,
                    "tipoDocumento": "37",
                    "numDocumento": billing_info.document,
                    "nombreComercial": billing_info.name,
                    "tipoPersona": 1,
                    "descActividad": billing_info.activity.value,
                    "telefono": billing_info.phone,
                    "correo": billing_info.email,
                }

                items.forEach(element => {
                    json.dteJson.cuerpoDocumento.push({
                        "numItem": counter++,
                        "cantidad": element.quantity,
                        "codigo": null,
                        "uniMedida": 59,
                        "descripcion": element.name,
                        "precioUni": payment.price,
                        "montoDescu": 0,
                        "ventaGravada": payment.price,
                        "tributos": [
                            "C3"
                        ],
                        "noGravado": 0
                    })
                });

            } else {

                json.dteJson.identificacion = {
                    "version": version,
                    "ambiente": environment.mh.environment,
                    "tipoDte": typeDte,
                    "numeroControl": control_number,
                    "codigoGeneracion": generation_code,
                    "tipoModelo": 1,
                    "tipoOperacion": 1,
                    "tipoContingencia": null,
                    "motivoContin": null,
                    "fecEmi": formattedDate,
                    "horEmi": formattedTime,
                    "tipoMoneda": "USD"
                }

                json.dteJson.emisor = {
                    "nit": this.auth.quanto_dte.nit,
                    "nrc": this.auth.quanto_dte.nrc,
                    "nombre": this.auth.quanto_dte.name,
                    "nombreComercial": this.auth.quanto_dte.commercial_name,
                    "codActividad": this.auth.quanto_dte.activity_code,
                    "descActividad": this.auth.quanto_dte.activity_name,
                    // "codActividad": '62010',
                    // "descActividad": 'Programación informática',
                    "tipoEstablecimiento": '02',
                    "direccion": {
                        "departamento": '06',
                        "municipio": '14',
                        "complemento": 'BLVD HIPODROMO COL SAN BENITO #582'
                    },
                    "telefono": '220305020',
                    "correo": 'info@quantopos.com',
                    "codEstableMH": null,
                    "codEstable": null,
                    "codPuntoVentaMH": null,
                    "codPuntoVenta": null
                }

                if (billing_info.ccf_required) {
                    json.dteJson.resumen = {
                        "totalNoSuj": 0,
                        "totalExenta": 0,
                        "totalGravada": this.components.fixed(payment.amount - billing_info.taxes),
                        "subTotalVentas": this.components.fixed(payment.amount - billing_info.taxes),
                        "descuNoSuj": 0,
                        "descuExenta": 0,
                        "descuGravada": 0,
                        "porcentajeDescuento": 0,
                        "totalDescu": 0,
                        "tributos": [{
                            "codigo": "20",
                            "descripcion": "Impuesto al Valor Agregado 13%",
                            "valor": this.components.fixed(billing_info.taxes)
                        }],
                        "subTotal": this.components.fixed(payment.amount - billing_info.taxes),
                        "ivaPerci1": 0,
                        "ivaRete1": 0,
                        "reteRenta": 0,
                        "montoTotalOperacion": this.components.fixed(payment.amount),
                        "totalNoGravado": 0,
                        "totalPagar": this.components.fixed(payment.amount),
                        "totalLetras": total_in_words,
                        "saldoFavor": 0,
                        "condicionOperacion": 1,
                        "pagos": [],
                        "numPagoElectronico": null
                    }
                    json.dteJson.receptor = {
                        "nrc": billing_info.nrc,
                        "nit": billing_info.nit,
                        "nombre": billing_info.name,
                        "codActividad": String(billing_info.activity.code),
                        "descActividad": billing_info.activity.value,
                        "correo": billing_info.email,
                        "nombreComercial": billing_info.name,
                        "direccion": {
                            "departamento": billing_info.depto.code,
                            "municipio": billing_info.munic.code,
                            "complemento": billing_info.address,
                        },
                        "telefono": billing_info.phone
                    }

                    items.forEach(element => {
                        json.dteJson.cuerpoDocumento.push({
                            "numItem": counter++,
                            "tipoItem": 2,
                            "numeroDocumento": null,
                            "codigo": null,
                            "codTributo": null,
                            "descripcion": element.name,
                            "cantidad": element.quantity,
                            "uniMedida": 59,
                            "precioUni": Number(this.components.fixed(element.price - element.taxes)),
                            "montoDescu": 0,
                            "ventaNoSuj": 0,
                            "ventaExenta": 0,
                            "ventaGravada": Number(this.components.fixed(element.price - element.taxes)),
                            "tributos": ["20"],
                            "psv": 0,
                            "noGravado": 0
                        });
                    });
                } else {
                    json.dteJson.resumen = {
                        "totalNoSuj": 0,
                        "totalExenta": 0,
                        "totalGravada": payment.amount,
                        "subTotalVentas": payment.amount,
                        "descuNoSuj": 0,
                        "descuExenta": 0,
                        "descuGravada": 0,
                        "porcentajeDescuento": 0,
                        "totalDescu": 0,
                        "tributos": null,
                        "subTotal": this.components.fixed(payment.amount),
                        "ivaRete1": 0,
                        "reteRenta": 0,
                        "montoTotalOperacion": payment.amount,
                        "totalNoGravado": 0,
                        "totalPagar": Number(payment.amount),
                        "totalLetras": total_in_words,
                        "totalIva": billing_info.taxes,
                        "condicionOperacion": 1,
                        "pagos": [],
                        "saldoFavor": 0,
                        "numPagoElectronico": null
                    }

                    json.dteJson.receptor = {
                        "tipoDocumento": null,
                        "numDocumento": null,
                        "nombre": billing_info.name, //Opcional null
                        "correo": billing_info.email,
                        "nrc": null, //Opcional null
                        "telefono": null, //Opcional null
                        "codActividad": null, //Opcional null
                        "descActividad": null, //Opcional null
                        "direccion": null, //Opcional null
                    }

                    items.forEach(element => {
                        json.dteJson.cuerpoDocumento.push({
                            "numItem": counter++,
                            "tipoItem": 2,
                            "cantidad": element.quantity,
                            "codigo": null,
                            "uniMedida": 59,
                            "descripcion": element.name,
                            "precioUni": this.components.fixed(element.price),
                            "montoDescu": 0,
                            "ventaNoSuj": 0,
                            "ventaExenta": 0,
                            "ventaGravada": this.components.fixed(element.price),
                            "tributos": null,
                            "psv": 0,
                            "noGravado": 0,
                            "numeroDocumento": null,
                            "codTributo": null,
                            "ivaItem": this.components.fixed(element.taxes)
                        })
                    });
                }
            }

            json.dteJson.resumen['pagos'].push({
                "montoPago": payment.amount,
                "referencia": null,
                "codigo": payment.method,
                "plazo": null,
                "periodo": null
            })

            resolve(json)
        })
    }

    sendEmail(payment) {
        return new Promise((resolve, reject) => {
            let postData = {
                payment_key: payment.$key
            }

            this.http.post(`${environment.api}/send_payment_email`, postData).subscribe(response => {
                resolve(true);
            })
        })
    }

}