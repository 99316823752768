// export const environment = {
//   production: false,
//   api_url: "",
//   payment_url: "",
//   cloudinary: {
//     api_key: '321842221971572',
//     cloud_name: 'ddqvtwvdk',
//     api_secret: 'ICDezxj5Oqm0cHRrZ8jeKT6IAUk',
//     upload: {
//       url: 'https://api.cloudinary.com/v1_1/ddqvtwvdk/image/upload',
//     },
//     delete: {
//       url: 'https://api.cloudinary.com/v1_1/ddqvtwvdk/image/destroy',
//     }
//   },
//   algolia: {
//     appId: 'QINPD3BNMP',
//     apiKey: 'dcf0fa9d9a76502f7d697ef002fcd2fb'
//   },
//   mh: {
//     environment: '00',
//     url: 'https://apitest.dtes.mh.gob.sv'
//   },
//   emails_url: 'https://us-central1-quanto-software.cloudfunctions.net/emails-emails',
//   api: 'https://sandbox.quantopos.com',
//   payment_link: 'https://testing-payment-link.quantopos.com'
//   // api: 'http://127.0.0.1:5002'
// };

// export const firebaseConfig = {
//   apiKey: "AIzaSyBmreAMoptKaFVLWQb_oOl4agWhFR-KVA4",
//   authDomain: "quanto-sandbox.firebaseapp.com",
//   projectId: "quanto-sandbox",
//   storageBucket: "quanto-sandbox.appspot.com",
//   messagingSenderId: "451046662365",
//   appId: "1:451046662365:web:9ca520c09b78cb719a3b34"
// }

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
    production: false,
    api_url: "",
    payment_url: "",
    cloudinary: {
        api_key: '321842221971572',
        cloud_name: 'ddqvtwvdk',
        api_secret: 'ICDezxj5Oqm0cHRrZ8jeKT6IAUk',
        upload: {
            url: 'https://api.cloudinary.com/v1_1/ddqvtwvdk/image/upload',
        },
        delete: {
            url: 'https://api.cloudinary.com/v1_1/ddqvtwvdk/image/destroy',
        }
    },
    algolia: {
        appId: 'TPGQ58F06T',
        apiKey: '204b9d953eb1c42cc8f05ecb235a6f78'
    },
    emails_url: 'https://us-central1-quanto-software.cloudfunctions.net/emails-emails',
    api: 'https://apitest.quantopos.com',
    mh: {
        environment: '00',
        url: 'https://apitest.dtes.mh.gob.sv'
    }
};

export const firebaseConfig = {
    apiKey: "AIzaSyCXQ5pc8OrB4sm8KBJSFKs10ooPWyNirQ4",
    authDomain: "quanto-develop.firebaseapp.com",
    databaseURL: "https://quanto-develop-default-rtdb.firebaseio.com",
    projectId: "quanto-develop",
    storageBucket: "quanto-develop.appspot.com",
    messagingSenderId: "587942193426",
    appId: "1:587942193426:web:12fe044950532a7f75abcb"
}


